<template>
  <div class="up-request">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <h1 class="up-main-title">Просмотр заявки</h1>
    <template v-if="dataReceived">
      <RequestForm v-model="form" is-edit ref="form" />
      <AppBlockAsTable
        v-if="listeners.length > 0"
        class="up-request__listeners up-listeners"
      >
        <template slot="header">
          <p class="up-listeners__fio">ФИО</p>
          <p class="up-listeners__email">Email</p>
          <p class="up-listeners__phone">Телефон</p>
          <p class="up-listeners__progress">Прогресс</p>
          <p class="up-listeners__status">Статус</p>
          <p class="up-listeners__actions"></p>
        </template>
        <!-- TODO как будет время - вынести в компонент -->
        <div
          v-for="(l, i) in listeners"
          :key="`listener${i}-${l.id}`"
          class="up-table__item"
        >
          <p class="up-listeners__fio">
            <span class="is-pk-hidden">ФИО:</span> {{ l.name }}
          </p>
          <p class="up-listeners__email">
            <span class="is-pk-hidden">Email:</span> {{ l.email }}
          </p>
          <p class="up-listeners__phone">
            <span class="is-pk-hidden">Телефон:</span> {{ l.phone }}
          </p>
          <p class="up-listeners__progress">
            <span class="is-pk-hidden">Прогресс:</span> {{ l.progressInfo }}
          </p>
          <p class="up-listeners__status">
            <span class="is-pk-hidden">Статус:</span>
            <el-tag>{{ statusTranslated(l) }}</el-tag>
          </p>
          <p class="up-listeners__actions">
            <el-dropdown @command="dropdownAction($event, l)" trigger="click">
              <i class="el-icon-more" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="showOpenAccess(l)"
                  command="openAccessToListener"
                >
                  <i class="el-icon-unlock" /> открыть доступ
                </el-dropdown-item>
                <el-dropdown-item v-else command="closeAccessToListener">
                  <i class="el-icon-lock" /> закрыть доступ
                </el-dropdown-item>
                <el-dropdown-item command="show-results">
                  <i class="el-icon-view" /> посмотреть результаты курса
                </el-dropdown-item>
                <el-dropdown-item command="removeListenerFromRequest">
                  <i class="el-icon-delete" /> удалить слушателя из заявки
                </el-dropdown-item>
                <!-- Marat: -->
                <el-dropdown-item command="resetPasswordToListener">
                  <i class="el-icon-password" /> сбросить пароль
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </AppBlockAsTable>
      <el-alert
        v-else
        class="up-request__alert"
        type="warning"
        :closable="false"
        title="Внимание!"
      >
        Добавьте хотя бы одного слушателя в заявку перед сохранением
      </el-alert>
      <div class="up-request__buttons">
        <el-button
          class="up-request__btn-add-listener"
          @click="addListenersModalVisible = true"
        >
          Добавить слушателя
        </el-button>
        <el-button type="warning" @click="deleteHandler" v-if="listeners.length == 0">Удалить пустую заявку</el-button>
        <el-button type="primary" @click="openAccessModalVisible = true" v-if="listeners.length > 0">
          Открыть всем
        </el-button>
        <el-button type="success" @click="editHandler">Сохранить</el-button>
      </div>
      <AddListenersModal
        :visible.sync="addListenersModalVisible"
        :added-listeners="listeners"
        @add-listeners="addListenersHandler"
        @close="addListenersModalVisible = false"
      />
      <AppSimpleModal
        :visible.sync="simpleModalVisible"
        action-text="Подтвердить"
        :title="`Слушатель - ${listenerData.name}`"
        @close="simpleModalVisible = false"
        @action="simpleModalActionHandler"
      >
        {{ simpleModalText }}. Вы действительно хотите это сделать?
      </AppSimpleModal>
      <AppSimpleModal
        :visible.sync="openAccessModalVisible"
        action-text="Подтвердить"
        title="Открыть доступ всем"
        @close="openAccessModalVisible = false"
        @action="openAccessToAll"
      >
        Вы собираетесь открыть доступ всем слушателям. Действительно хотите
        сделать это?
      </AppSimpleModal>
      <AppDetailsModal
        :visible.sync="detailsModalVisible"
        :listener-id="listenerData.id"
        :title="`Данные курса слушателя: ${listenerData.name}`"
      />
    </template>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import AppSimpleModal from "@/components/AppSimpleModal";
import RequestForm from "@/components/manager/requests/RequestForm";
import AddListenersModal from "@/components/manager/requests/AddListenersModal";
import AppDetailsModal from "@/components/AppDetailsModal";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "ShowRequest",
  mixins: [showErrorMessageMixin],
  components: {
    AppBlockAsTable,
    AppBreadcrumbs,
    AppSimpleModal,
    RequestForm,
    AddListenersModal,
    AppDetailsModal,
  },
  data: () => ({
    dataReceived: false,
    form: {
      customerId: null,
      courseId: null,
      dateUntil: null,
      orderStatus: null,
    },
    addListenersModalVisible: false,
    simpleModalVisible: false,
    openAccessModalVisible: false,
    detailsModalVisible: false,
    requestData: {},
    listeners: [],
    listenerData: {},
    listenerCommand: "",
    breadcrumbs: [
      {
        text: "Все заявки",
        path: "/manager/requests",
      },
      {
        text: "Текущая заявка",
      },
    ],
  }),
  computed: {
    simpleModalText() {
      let text = "Вы собираетесь закрыть доступ слушателю";

      if (this.listenerCommand === "openAccessToListener") {
        text = "Вы собираетесь открыть доступ слушателю";
      } else if (this.listenerCommand === "removeListenerFromRequest") {
        text = "Вы собираетесь удалить слушателя";
      } else if (this.listenerCommand === "resetPasswordToListener") {
        text = "Вы собираетесь сбросить пароль для пользователя";
      }
      return text;
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    setData() {
      this.form.customerId = this.requestData.customer.id;
      this.form.dateUntil = this.requestData.dateUntil;
      this.form.courseId = this.requestData.course.id;
      this.form.orderStatus = this.requestData.orderStatus;
      this.listeners = this.requestData.listeners || [];
    },
    async fetchRequestData() {
      try {
        const { data: requestData } = await this.$axios.get(
          `/manager/order/${this.$route.params.id}`
        );

        this.requestData = requestData;
        this.dataReceived = true;
        this.setData();
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async openAccessToListener() {
      let requestData;
      const baseUrl = `/manager/order/${this.$route.params.id}/listener/${this.listenerData.id}`;
      const isFlowClosed =
        this.listenerData.flow
          && (this.listenerData.flow.flowState === "closed" || this.listenerData.flow.flowState === "timeout");

      try {
        if (isFlowClosed) {
          requestData = await this.$axios.patch(`${baseUrl}/unlock`);
        } else {
          requestData = await this.$axios.post(`${baseUrl}/createFlow`);
        }

        this.listeners = requestData.data.listeners  || [];

        this.$message({
          message: `Поздравляем! Доступ слушателю ${this.listenerData.name} открыт`,
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async openAccessToAll() {
      try {
        const { data: res } = await this.$axios.post(
          `/manager/order/${this.$route.params.id}/createFlows`
        );

        this.listeners = res.listeners  || [];
        this.openAccessModalVisible = false;

        this.$message({
          message: "Поздравляем! Доступ всем слушателям открыт",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async closeAccessToListener() {
      try {
        const { data: res } = await this.$axios.patch(
          `/manager/order/${this.$route.params.id}/listener/${this.listenerData.id}/lock`
        );

        this.listeners = res.listeners || [];

        this.$message({
          message: "Поздравляем! Доступ слушателю закрыт.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },

    // Marat:
    async resetPasswordToListener() {
      try {
        await this.$axios.patch(
          `/manager/order/${this.$route.params.id}/listener/${this.listenerData.id}/resetPassword`
        );

        this.$message({
          message: "Новый пароль успешно отправлен пользователю.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },

    async removeListenerFromRequest() {
      try {
        const { data: res } = await this.$axios.delete(
          `/manager/order/${this.$route.params.id}/listener/${this.listenerData.id}`
        );

        this.listeners = res.listeners || [];

        this.$message({
          message: "Поздравляем! Слушатель был успешно удален.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    showOpenAccess(l) {
      return !l.flow || l.flow.flowState === "closed" || l.flow.flowState === "timeout";
    },
    statusTranslated(listener) {
      if (!listener.flow) return "Не активен";
      if (listener.flow.flowState === "none") return "Доступен";
      if (listener.flow.flowState === "active") return "Открыт";
      if (listener.flow.flowState === "done") return "Пройден";
      if (listener.flow.flowState === "deleted") return "Удален";
      if (listener.flow.flowState === "timeout") return "Завершен";

      return "Закрыт";
    },
    simpleModalActionHandler() {
      this[this.listenerCommand]();
      this.simpleModalVisible = false;
    },
    dropdownAction(command, listener) {
      this.listenerData = listener;
      this.listenerCommand = command;

      if (command === "show-results") {
        this.detailsModalVisible = true;
        return;
      }

      this.simpleModalVisible = true;
    },
    async editHandler(_, listenersArr) {
      if (!(await this.$refs.form.isValid())) return;

      try {
        const { data } = await this.$axios.post("/manager/order", {
          id: this.requestData.id,
          ...this.form,
          listeners: listenersArr || this.listeners,
        });

        this.listeners = data.listeners || [];

        this.$message({
          message: "Поздравляем! Данные заявки успешно обновлены.",
          type: "success",
        });

        this.addListenersModalVisible = false;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async deleteHandler(_, listenersArr) {
      if (!(await this.$refs.form.isValid())) return;

      try {
        const { data } = await this.$axios.delete("/manager/order/" + this.requestData.id, {
          id: this.requestData.id,
          ...this.form,
          listeners: listenersArr || this.listeners,
        });

        this.listeners = data.listeners || [];

        this.$message({
          message: "Заявка удалена.",
          type: "success",
        });

        this.addListenersModalVisible = false;
        this.$router.push('/manager/requests/');

      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async addListenersHandler(listenersArr) {
      await this.editHandler(null, [...this.listeners, ...listenersArr]);
    },
  },
  async created() {
    await this.fetchRequestData();

    if (!this.requestData.course) return;

    this.setNavigationText(
      `${this.requestData.number}: ${this.requestData.course.name}`
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/manager/requests/new-edit.scss";
</style>